$mainBackgroundColor: #DB4021;
$selectButtonBackgroundColor: #5E72E4;

html, body {
  overflow: auto;
}

/**
  Bryntum styles
*/
.b-sch-event {
  border-radius : 3px;
  box-shadow    : 0 1px 1px 0 rgba(0, 0, 0, .25);
}

#main {
  display        : flex;
  flex-direction : row;
  height         : 100%;

  .b-react-scheduler-container {
    flex     : 1 1 0;
    overflow : hidden;
  }

  #unplannedContainer {
    width       : 300px;

    .b-gridbase {
      flex        : none;
      border-left : 2px solid #aaa;

      .b-grid-cell {
        cursor : pointer;
      }

      i {
        margin-right : 10px;
        color        : #4887e5;
      }
    }
  }
}

.b-grid-header {
  height: 57px;
}

.b-sch-event.b-drag-invalid {
  background : red;
}

.b-unassigned-class.b-drag-proxy:not(.b-drag-invalid) {
  background : green;
}

.b-aborting.b-unassigned-class {
  transition : transform 0.3s !important;

  // https://app.assembla.com/spaces/bryntum/tickets/7168-drag-drop-cleanup-code-on-abort-is-interfering-with-animation/details#
  background     : red;
  position       : absolute !important;
  z-index        : 10000;
  pointer-events : none;
  opacity        : 0.8;
  box-sizing     : border-box;
}

.b-gridbase:not(.b-autoheight) .b-grid-body-container {
  flex: auto;
  height: 500px;
}

// eof
/**
  Bryntum styles end
*/



h1 {
  font-size: 2rem;
}

.form-control-label {
  font-size: 1rem;
  color: black;
}

.form-control {
  color: black;
}

h1.surtitle,
h2.surtitle {
  color: black;
}

th, td {
  color: black !important;
}

.text-black {
  color: black !important;
}

.bg-info {
  background-color: $mainBackgroundColor !important;
}

.admin-main-body {
  min-height: 75vh;
}

.profile-file-input {
  opacity: 0 !important;
}

.selectedButton {
  background-color: $selectButtonBackgroundColor;
  color: white;
}

.releasedSelectedButton {
  background-color: white;
  color: $selectButtonBackgroundColor;
}

.border-2 {
  border-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pt-3-4 {
  padding-top: 1.75rem !important;
}

.pt-4-5 {
  padding-top: 2rem !important;
}

.carousel-trash {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* For Chrome */
/* width */
.carousel-indicators::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.carousel-indicators::-webkit-scrollbar-track {
  background: transparent; 
}

/* Handle */
.carousel-indicators::-webkit-scrollbar-thumb {
  background: #bbb; 
}

/* Handle on hover */
.carousel-indicators::-webkit-scrollbar-thumb:hover {
  background: #4b4b4b; 
}

.alert-no-contact {
  color: #ffffff;
  border-color: #039dfc;
  background-color: #039dfc;
}

.fleet-table-button {
  padding: 0.225rem 1.25rem;
}

.b-grid-header-container {
  z-index: 0;
}

.b-resource-info dl {
  margin-bottom: 0;
}

#main #unplannedContainer {
  width: 200px;
}

.event-filter-date.form-control {
  height: calc(1.5em + 1.1rem);
}

.device-item, 
.trip-item {
  padding: 8px 5px;
  align-items: center;
  cursor: pointer;
}

.device-item:hover {
  background-color: #5C5C5C;
  transition: 0.5s ease;
}

.trip-item:hover {
  background-color: #8a4d4c;
  transition: 0.5s ease;
}

.selected-device-item {
  background-color: #5C5C5C;
}

.selected-trip-item {
  background-color: #8a4d4c;
}

.white-label {
  color: white;
}

textarea.vehicle-data-display {
  width: 100%;
  min-height: 34.2rem;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 0.8rem;
  line-height: 1.2;
}

table tr.bold-row {
  font-weight: 700;
}

@media (min-width: 768px) {
  .deal-tab.col-md-2 {
    flex: 0 0 15.57%;
    max-width: 15.57%; 
  }
}

@media (max-width: 600px) {
  .col-8 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .col-4 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .col-2 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
}

@media (max-width: 1199px) {
  .mt-7.dashboard {
    margin-top: 23rem !important;
  }
}